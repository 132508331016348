<template>
    <b-row class="rowbg">
        <b-col md="12">
            <b-card>
                <b-card-title>Het heerlijk avondje…</b-card-title>
                <b-card-text>
                    <b-img align="right" class="w-50 cardimage"  v-bind:src="$imagestore1+'/website%202018/Ridder-en-de-Prins---Pieten-huisbezoek.jpg'" fluid></b-img>
                    <p>Om uw Pakjesavond goed en sfeervol op te starten komen wij met veel plezier en de grootste zorg langs. Piet & Piet zijn goed voorbereid en weten snel de huiskamer te vullen met gelach en plezier. Na ongeveer 20 minuten geven we de ruimte
                        terug om als familie het feest verder te vieren, met een fantastische start om nog uren over door te praten.</p>
                    <p> Piet & Piet lopen over de daken in en alle plaatsen direct rondom Utrecht en komen langs voor €45,00. Zij nemen hun repertoire en Grote Boek mee. Piet & Sint komen langs voor €70,00. Lees vooral ook de gemakken van ons Grote Boek.</p>
                </b-card-text>
            </b-card>
            <b-card>
                <b-card-title>Het gemak van het Grote Boek</b-card-title>
                <b-card-text>
                    <b-img align="left" class="w-50 cardimage"  v-bind:src="$imagestore1+'/website%202018/Ridder-en-de-Prins---Piet-met-Grote-boek.jpg'" fluid></b-img>
                    <p>Hoe komen we naar binnen? Waar staan de cadeaus? Wie is de bridgepartner van oma? Het Grote Boek geeft gelukkig op al die vragen antwoord. Na een reservering ontvangt u toegang tot een invulformulier op onze website. Daar kunt u allerlei
                        nuttige informatie over het gezin of de familie kwijt. Ook krijgt u nuttige tips vanuit onze jarenlange ervaring. U kunt tot twee weken voor het bezoek de informatie invullen en herzien als er nog veranderingen zijn. Zo weten Piet
                        & Piet alles ruim van te voren en kunnen ze het bezoek goed voorbereiden.</P>
                </b-card-text>
            </b-card>
            <b-card>
                <b-card-title> Repertoire van Piet</b-card-title>
                <b-card-text>
                    <p>Sinds dag één zijn we bezig met het ontwikkelen van ons eigen repertoire. Rustige en stille verhalen tot energieke spellen over paarden, pepernoten of stoomboten. Ook maken we liedjes over tandenpoetsen, buiten spelen of knutselen, vaak
                        begeleid met een videoclip ter voorpret. Onze liedjes gaan over mooie kinderthema’s die we goed samen met de kinderen kunnen uitbeelden. Zo is Piet er voor de groep en focust Sint zich op het individu.</p>
                    <p>Enkele van onze eigen nummers zijn:</p>
                    <silent-box class="galleryitems" :gallery="repertoire"></silent-box>
                </b-card-text>
            </b-card>
            <b-card>
                <b-card-title>Reserveer direct</b-card-title>
                <b-card-text>
                   <b-link to="/contact">
                        <b-img align="right" class="w-50 cardimage"  v-bind:src="$imagestore1+'/website%202018/Ridder-en-de-Prins---Piet-schrijft-in-het-Grote-boek.jpg'" fluid alt="Fluid image"></b-img>
                    </b-link>
                    <p>
                    Vul het contactformulier in met uw wensen en wij nemen zo snel mogelijk contact op.
                    </p>
                    <b-link to="/contact">
                        <b-img align="left" fluid alt="Fluid image" v-bind:src="$imagestore1+'/logo/boeknu.png'" ></b-img>
                    </b-link> 
                    
                </b-card-text>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
    export default {
        name: 'Huisbezoek',
        data: function() {
            return {
                repertoire: [{
                        src: 'https://www.youtube.com/watch?v=nvkMp7pF-4U'
                    },
                    {
                        src: 'https://www.youtube.com/watch?v=7IdAfQsrcdw'
                    },
                    {
                        src: 'https://www.youtube.com/watch?v=hIuchunSDSs'
                    }
                ]
            }
        }
    }
</script>

<style>

</style>